import React from "react";
import Layout from "components/layout";
import PostBody from "components/PostBody";
import PostHeader from "components/PostHeader";
import LocalizedLink from "components/localizedLink";
import JobListing from "components/JobListing"
import { graphql } from "gatsby";
import { rhythm } from "utils/typography";
import SEO from "components/seo";
import { colors } from "style/theme";
import { jsx } from "@emotion/core";
import { mq } from "utils/helper";

const Jobs = ({ data, location }) => {
  const { pageContent, jobListings } = data;
  const { frontmatter, body } = pageContent;
  const siteTitle = frontmatter.title;

  return (
    <div
      css={{
        backgroundColor: colors.dark,
        position: "relative",
        overflow: "hidden"
      }}
    >
      <img
        src="/assets/about-background.gif"
        css={{
          position: "absolute",
          top: 0,
          left: "30%",
          width: "60%",
          height: "auto",
          zIndex: 0,
          opacity: 0.35
        }}
      />
      <Layout title={siteTitle} css={{ zIndex: 2 }}>
        <SEO title={siteTitle} />
        <PostHeader post={pageContent} location={location} />
        <div
          css={{
            maxWidth: rhythm(34),
            marginBottom: rhythm(4),
            margin: "auto"
          }}
        >
          <PostBody body={body} />
          <h5
            css={{
              color: colors.light,
              fontWeight: "bold",
              fontSize: "18px",
              marginTop: "2rem"
            }}
          >
            Open positions:
          </h5>
          <div>
            {jobListings.edges.map(({ node }) => (
              <JobListing item={node} key={node.id} />
            ))}
          </div>

        </div>
      </Layout>
    </div>
  );
}

export default Jobs;

export const pageQuery = graphql`
  query jobsPage {
    pageContent: mdx(fileAbsolutePath: {regex: "/content/about/"}, slug: {eq: "about/jobs.en"}) {
      frontmatter {
        title
      }
      body
    }
    jobListings: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/content/about/jobs\/.*/" }
        frontmatter: { draft: { ne: true } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date
            location
            deadline
            active

          }
        }
      }
    }
  }
`;
